import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import Progress from "../../common/Progress";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import "./ShippingInfo.scss";
import useGlobal from "../../../store";
import close from "../../../assets/close.svg";
import alert from "../../../assets/alert-warning@1x.png";
import ValidateZipCode from "../../../api/ValidateZipCode";
import SaveOrder from "../../../api/SaveOrder";
import SaveOrderDetails from "../../../api/SaveOrderDetails";
import { ShippingType } from "../../common/CommonList";
import { Spinner } from "../../common/spinner";
import { trackPromise } from "react-promise-tracker";

import {
  BindSaveOrder,
  BindBirthCertificate,
  BindDeathVerification,
  BindDivorceVerification,
  BindMarriageVerification,
  BindDeathCertificate,
  BindBirthVerification,
  BindFetalDeath,
} from "./ShippingInfoHelper";
import history from "../../common/history";
import { isMobileOnly } from "react-device-detect";

function ShippingInfo(props) {
  const stateList = props.stateList;
  const [showError, setShowError] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const showCheckbox = globalState.verifyIdentityData.idVerified;
  const shippingType = ShippingType();
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  if (
    globalState.totalOrderRecords === undefined ||
    globalState.totalOrderRecords.length === 0
  ) {
    history.push(t("URLS.SELECT_VITAL_RECORD"));
  }

  const SignupSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t("SHIPPING_INFORMATION_ERROR_MSG.FIRST_NAME"))
      .matches(
        /(?=^.{1,50}$)^([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("SHIPPING_INFORMATION_ERROR_MSG.FIRST_NAME_SP")
      ),
    lastName: yup
      .string()
      .required(t("SHIPPING_INFORMATION_ERROR_MSG.LAST_NAME"))
      .matches(
        /(?=^.{2,50}$)^([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("SHIPPING_INFORMATION_ERROR_MSG.LAST_NAME_SP")
      ),
    middleName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("SHIPPING_INFORMATION_ERROR_MSG.MIDDLE_NAME_SP")
      ),
    city: yup
      .string()
      .required(t("SHIPPING_INFORMATION_ERROR_MSG.CITY"))
      .min(2, t("SHIPPING_INFORMATION_ERROR_MSG.CITY_MIN_LENGTH"))
      .matches(/^[a-zA-Z ]*$/, t("SHIPPING_INFORMATION_ERROR_MSG.CITY")),
    streetAddress1: yup
      .string()
      .required(t("SHIPPING_INFORMATION_ERROR_MSG.STREET_ADDRESS1")),
    zipCode: yup
      .string()
      .required(t("SHIPPING_INFORMATION_ERROR_MSG.ZIP_CODE"))
      .min(5, t("SHIPPING_INFORMATION_ERROR_MSG.ZIP_CODE"))
      .max(5, t("SHIPPING_INFORMATION_ERROR_MSG.ZIP_CODE"))
      .matches(
        /^[_0-9]*((-|\s)*[_0-9])*$/,
        t("SHIPPING_INFORMATION_ERROR_MSG.ZIP_CODE")
      ),
    phoneNumber: yup
      .string()
      .required(t("SHIPPING_INFORMATION_ERROR_MSG.PHONE_NUMBER"))
      .min(10)
      .max(10)
      .matches(
        /^[_0-9]*((-|\s)*[_0-9])*$/,
        t("SHIPPING_INFORMATION_ERROR_MSG.PHONE_NUMBER")
      ),
    selectedState: yup.object().shape({
      label: yup
        .string()
        .required(t("COMMON_ERROR_MSG.STATE_REQUIRED"))
        .typeError(t("COMMON_ERROR_MSG.STATE_REQUIRED")),

      value: yup
        .string()
        .required(t("COMMON_ERROR_MSG.STATE_REQUIRED"))
        .typeError(t("COMMON_ERROR_MSG.STATE_REQUIRED")),
    }),
    selectedShippingType: yup.object().shape({
      label: yup
        .string()
        .required(t("COMMON_ERROR_MSG.SHIPPING_TYPE_REQUIRED")),

      value: yup
        .string()
        .required(t("COMMON_ERROR_MSG.SHIPPING_TYPE_REQUIRED")),
    }),
    email: yup
      .string()
      .required(t("SHIPPING_INFORMATION_ERROR_MSG.EMAIL_ERROR"))
      .email(t("SHIPPING_INFORMATION_ERROR_MSG.EMAIL")),

    confEmail: yup
      .string()
      .required(t("SHIPPING_INFORMATION_ERROR_MSG.EMAIL"))
      .email(t("SHIPPING_INFORMATION_ERROR_MSG.EMAIL"))
      .when("email", {
        is: (email) => (email && email.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref("email")], t("SHIPPING_INFORMATION_ERROR_MSG.EMAIL")),
      }),
    attestionCheck: yup
      .boolean()
      .oneOf([true], t("SHIPPING_INFORMATION_ERROR_MSG.ATTESTATION")),
  });

  const { register, handleSubmit, control, errors, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });

  const integrateSaveOrder = (validateRespone, props) => {
    trackPromise(
      SaveOrder(BindSaveOrder(globalState, validateRespone, props)).then(
        (response) => {
          if (response.data.orderNumber !== "") {
            globalActions.addOrderNumber(response.data.orderNumber);
            integrateSaveOrderDetails(response.data);
          } else {
            setShowError(true);
          }
        }
      )
    ).catch(function (error) {
      console.log("SaveOrder error", error);
      history.push(t("URLS.ERROR_PAGE"));
    });
  };

  const integrateSaveOrderDetails = (saveOrderResponse) => {
    var saveOrderDetails = {};
    saveOrderDetails["applicationId"] = "OVRA";
    saveOrderDetails["agencyId"] = "DSHS";
    saveOrderDetails["uniqueIdentifier"] = globalState.uniqueIdentifier;
    saveOrderDetails["orderNumber"] = saveOrderResponse.orderNumber;
    if (globalState.totalOrderRecords.length > 0) {
      globalState.totalOrderRecords.forEach(function (item, index) {
        if (item.certificateInfoType === "deathVerification") {
          if (
            saveOrderDetails["deathVerifications"] === undefined ||
            saveOrderDetails["deathVerifications"] === "" ||
            saveOrderDetails["deathVerifications"].length === 0
          ) {
            saveOrderDetails["deathVerifications"] = [];
          }
          saveOrderDetails["deathVerifications"].push(
            BindDeathVerification(item)
          );
        } else if (item.certificateInfoType === "birthCertificate") {
          if (
            saveOrderDetails["birthCertificates"] === undefined ||
            saveOrderDetails["birthCertificates"] === "" ||
            saveOrderDetails["birthCertificates"].length === 0
          ) {
            saveOrderDetails["birthCertificates"] = [];
          }
          let bindBirthCertificate = BindBirthCertificate(item);
          bindBirthCertificate.forEach(function (birthCertificate) {
            saveOrderDetails["birthCertificates"].push(birthCertificate);
          });
        } else if (item.certificateInfoType === "fetalDeath") {
          if (
            saveOrderDetails["fetaldeathCertificates"] === undefined ||
            saveOrderDetails["fetaldeathCertificates"] === "" ||
            saveOrderDetails["fetaldeathCertificates"].length === 0
          ) {
            saveOrderDetails["fetaldeathCertificates"] = [];
          }
          let bindFetalDeath = BindFetalDeath(item);
          bindFetalDeath.forEach(function (fetalDeathCertificate) {
            saveOrderDetails["fetaldeathCertificates"].push(fetalDeathCertificate);
          });
        } else if (item.certificateInfoType === "stillbirthDeath") {
          if (
            saveOrderDetails["stillBirthCertificates"] === undefined ||
            saveOrderDetails["stillBirthCertificates"] === "" ||
            saveOrderDetails["stillBirthCertificates"].length === 0
          ) {
            saveOrderDetails["stillBirthCertificates"] = [];
          }
          let bindFetalDeath = BindFetalDeath(item);
          bindFetalDeath.forEach(function (fetalDeathCertificate) {
            saveOrderDetails["stillBirthCertificates"].push(fetalDeathCertificate);
          });
        } else if (item.certificateInfoType === "divorceVerification") {
          if (
            saveOrderDetails["divorceVerifications"] === undefined ||
            saveOrderDetails["divorceVerifications"] === "" ||
            saveOrderDetails["divorceVerifications"].length === 0
          ) {
            saveOrderDetails["divorceVerifications"] = [];
          }
          saveOrderDetails["divorceVerifications"].push(
            BindDivorceVerification(item)
          );
        } else if (item.certificateInfoType === "marriageVerification") {
          if (
            saveOrderDetails["marriageVerifications"] === undefined ||
            saveOrderDetails["marriageVerifications"] === "" ||
            saveOrderDetails["marriageVerifications"].length === 0
          ) {
            saveOrderDetails["marriageVerifications"] = [];
          }
          saveOrderDetails["marriageVerifications"].push(
            BindMarriageVerification(item)
          );
        } else if (item.certificateInfoType === "deathCertificate") {
          if (
            saveOrderDetails["deathCertificates"] === undefined ||
            saveOrderDetails["deathCertificates"] === "" ||
            saveOrderDetails["deathCertificates"].length === 0
          ) {
            saveOrderDetails["deathCertificates"] = [];
          }
          saveOrderDetails["deathCertificates"].push(
            BindDeathCertificate(item)
          );
        } else if (item.certificateInfoType === "birthVerification") {
          if (
            saveOrderDetails["birthVerifications"] === undefined ||
            saveOrderDetails["birthVerifications"] === "" ||
            saveOrderDetails["birthVerifications"].length === 0
          ) {
            saveOrderDetails["birthVerifications"] = [];
          }
          saveOrderDetails["birthVerifications"].push(
            BindBirthVerification(item)
          );
        }
      });
    }
    trackPromise(
      SaveOrderDetails(saveOrderDetails)
        .then((response) => {
          if (response.status === 200) {
            props.nextCallback();
          } else {
            history.push(t("URLS.ERROR_PAGE"));
          }
        })
        .catch(function (error) {
          console.log("SaveOrderDetails error", error);
          history.push(t("URLS.ERROR_PAGE"));
        })
    );
  };
  const onSubmit = (data) => {
    setNextButtonDisabled(true);
    globalActions.shippingInformationData(data);
    var validateData = {
      applicationId: "OVRA",
      agencyId: "DSHS",
      uniqueIdentifier: globalState.uniqueIdentifier,
      zipCode: data.zipCode,
      state: data.selectedState.value,
      shippingType: data.selectedShippingType.value,
    };
    trackPromise(
      ValidateZipCode(validateData).then((response) => {
        if (response.data.status === "Success") {
          integrateSaveOrder(response.data, data);
        } else {
          setShowError(true);
          setNextButtonDisabled(false);
        }
      })
    ).catch(function (error) {
      console.log("ValidateZipCode error", error);
      history.push(t("URLS.ERROR_PAGE"));
      setShowError(true);
      setNextButtonDisabled(false);
    });
  };

  const [showAddress, setShowAddress] = useState(false);
  const showAddressInfo = () => {
    setShowAddress(!showAddress);
    !showAddress
      ? setValue("selectedState", globalState.verifyIdentityData.selectedState)
      : setValue("selectedState", "");
  };

  return (
    <>
      <Container className="order-review-container">
        <Progress currentStep={3} totalSteps={4} />
        <form
          className="shipping-info-section"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <h1 className="shipping-info-heading">
              {t("SHIPPING_INFORMATION.HEADING")}
            </h1>
            {showCheckbox === "Y" && (
              <div className="form-checkbox-default">
                <input
                  type="checkbox"
                  id="checkbox1"
                  className="checkbox"
                  onChange={showAddressInfo}
                />
                <label htmlFor="checkbox1" className="checkbox-shipping">
                  {t("SHIPPING_INFORMATION.DESCRIPTION")}
                </label>
              </div>
            )}
          </div>
          <div className="personal-information-div">
            <h2 className="personal-information">
              {t("SHIPPING_INFORMATION.PERSONAL_INFORMATION")}
            </h2>
            <label htmlFor="firstName" className="name">
              {t("SHIPPING_INFORMATION.FIRST_NAME")}
            </label>
            <div className="input-align">
              <input
                className={`answer ${errors.firstName ? "error-red" : ""}`}
                type="text"
                name="firstName"
                id="firstName"
                ref={register}
                rules={{ required: true }}
                defaultValue={
                  showAddress
                    ? globalState.verifyIdentityData.firstName
                    : globalState.shippingInformationData.firstName
                      ? globalState.shippingInformationData.firstName
                      : ""
                }
              />
              {errors.firstName && (
                <p className="error-red-content">{errors.firstName.message}</p>
              )}
            </div>
            <div className="input-align">
              <label htmlFor="middleName" className="name">
                {t("SHIPPING_INFORMATION.MIDDLE_NAME")}
                <span className="optional-text">{t("COMMONS.OPTIONAL")}</span>
              </label>
              <input
                className={`answer ${errors.middleName ? "error-red" : ""}`}
                type="text"
                name="middleName"
                id="middleName"
                ref={register}
                defaultValue={
                  showAddress
                    ? globalState.verifyIdentityData.middleName
                    : globalState.shippingInformationData.middleName
                      ? globalState.shippingInformationData.middleName
                      : ""
                }
              />
              {errors.middleName && (
                <p className="error-red-content">{errors.middleName.message}</p>
              )}
            </div>
            <div className="input-align">
              <label htmlFor="lastName" className="name">
                {t("SHIPPING_INFORMATION.LAST_NAME")}
              </label>
              <input
                className={`answer ${errors.lastName ? "error-red" : ""}`}
                type="text"
                name="lastName"
                id="lastName"
                ref={register}
                rules={{ required: true }}
                defaultValue={
                  showAddress
                    ? globalState.verifyIdentityData.lastName
                    : globalState.shippingInformationData.lastName
                      ? globalState.shippingInformationData.lastName
                      : ""
                }
              />
              {errors.lastName && (
                <p className="error-red-content">{errors.lastName.message}</p>
              )}
            </div>
          </div>
          <div className="new-address-copy">
            <h1 className="texas-driver-license">
              {t("SHIPPING_INFORMATION.SHIPPING_ADDRESS")}
            </h1>
            <label htmlFor="streetAddress1" className="name">
              {t("SHIPPING_INFORMATION.STREET_ADDRESS")}
            </label>
            <div>
              <input
                className={`answer ${errors.streetAddress1 ? "error-red" : ""}`}
                type="text"
                name="streetAddress1"
                id="streetAddress1"
                ref={register}
                rules={{ required: true }}
                defaultValue={
                  showAddress
                    ? globalState.verifyIdentityData.streetAddress1
                    : globalState.shippingInformationData.streetAddress1
                      ? globalState.shippingInformationData.streetAddress
                      : ""
                }
              />
              {errors.streetAddress1 && (
                <p className="error-red-content">
                  {errors.streetAddress1.message}
                </p>
              )}
              <input
                className="bg bg1 answer"
                type="text"
                name="streetAddress2"
                id="streetAddress1"
                ref={register}
                rules={{ required: true }}
                defaultValue={
                  showAddress
                    ? globalState.verifyIdentityData.streetAddress2
                    : globalState.shippingInformationData.streetAddress2
                      ? globalState.shippingInformationData.streetAddress2
                      : ""
                }
              />
            </div>
            <div className="text-field-1">
              <label htmlFor="city" className="name">
                {t("SHIPPING_INFORMATION.CITY")}
              </label>
              <input
                className={`answer ${errors.city ? "error-red" : ""}`}
                type="text"
                name="city"
                id="city"
                ref={register}
                rules={{ required: true }}
                defaultValue={
                  showAddress
                    ? globalState.verifyIdentityData.city
                    : globalState.shippingInformationData.city
                      ? globalState.shippingInformationData.city
                      : ""
                }
              />
              {errors.city && (
                <p className="error-red-content">{errors.city.message}</p>
              )}
            </div>

            <label className="birth-info-name">
              {t("SHIPPING_INFORMATION.STATE")}
            </label>
            <div className="info-input-align">
              <Controller
                name="selectedState"
                className={`field-label-padding ${errors.selectedState ? "error-red" : ""
                  }`}
                as={Select}
                options={stateList}
                control={control}
                defaultValue={
                  globalState.shippingInformationData.selectedState.label
                    ? globalState.shippingInformationData.selectedState
                    : ""
                }
                rules={{ required: true }}
              />
              {errors.selectedState && (
                <p className="error-red-content">
                  {t("COMMON_ERROR_MSG.STATE_REQUIRED")}
                </p>
              )}
            </div>
            <div className="shipping-zip">
              <label htmlFor="zipCode" className="name">
                {t("SHIPPING_INFORMATION.ZIP")}
              </label>
              <div>
                <input
                  className={`bgZip answer ${errors.zipCode ? "error-red" : ""
                    }`}
                  type="text"
                  id="zipCode"
                  name="zipCode"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    showAddress
                      ? globalState.verifyIdentityData.zipCode
                      : globalState.shippingInformationData.zipCode
                        ? globalState.shippingInformationData.zipCode
                        : ""
                  }
                />
                {errors.zipCode && (
                  <p className="error-red-content">{errors.zipCode.message}</p>
                )}
              </div>
            </div>
          </div>
          <div className="delivery-options">
            <h1 className="texas-driver-license">
              {t("SHIPPING_INFORMATION.DELIVERY_OPTION")}
            </h1>
            <label className="para-5">
              {t("SHIPPING_INFORMATION.DELIVERY_OPTION_DESC1")}
            </label>
            <label className="para-5">
              {t("SHIPPING_INFORMATION.DELIVERY_OPTION_DESC2")}
            </label>
            <div className="para-5">
              <label className="para-space-align">
                {t("SHIPPING_INFORMATION.EXPRESS_DELIVERY_COSTS")}
              </label>
              <ul>
                <li>
                  {t("SHIPPING_INFORMATION.MOST_US_LOCATIONS")}
                  <label className="shipping-amount">
                    {t("SHIPPING_INFORMATION.SIXTEEN_DOLLARS")}
                  </label>
                </li>
                <li>
                  {t("SHIPPING_INFORMATION.PO_BOX")}
                  <label className="shipping-amount">
                    {t("SHIPPING_INFORMATION.TWENTY_TWO_DOLLARS")}
                  </label>
                </li>
                <li>
                  {t("SHIPPING_INFORMATION.MILITARY_ADD_OUTSIDE_US")}
                  <label className="shipping-amount">
                    {t("SHIPPING_INFORMATION.FOUR_DOLLARS")}
                  </label>
                </li>
              </ul>
            </div>

            <div className="info-input-align">
              <label className="name">
                {t("SHIPPING_INFORMATION.SHIPPING_TYPE")}
                <Controller
                  name="selectedShippingType"
                  className={`get-state-birth field-label-padding ${errors.selectedShippingType ? "error-red" : ""
                    }`}
                  as={Select}
                  options={shippingType}
                  control={control}
                  defaultValue={
                    globalState.shippingInformationData.selectedShippingType.label
                      ? globalState.shippingInformationData.selectedShippingType
                      : ""
                  }
                  rules={{ required: true }}
                />
                {errors.selectedShippingType && (
                  <p className="error-red-content">
                    {t("COMMON_ERROR_MSG.SHIPPING_TYPE_REQUIRED")}
                  </p>
                )}
              </label>
            </div>
            <div className="text-field-2">
              <label htmlFor="phoneNumber" className="name">
                {t("SHIPPING_INFORMATION.PHONE_NUMBER")}
              </label>
              <input
                className={`answer ${errors.phoneNumber ? "error-red" : ""}`}
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                ref={register}
                rules={{ required: true }}
                defaultValue={
                  globalState.shippingInformationData.phoneNumber
                    ? globalState.shippingInformationData.phoneNumber
                    : ""
                }
              />
              {errors.phoneNumber && (
                <p className="error-red-content">
                  {errors.phoneNumber.message}
                </p>
              )}
            </div>
          </div>

          <h1 className="texas-driver-license">
            {t("SHIPPING_INFORMATION.EMAIL_CONF")}
          </h1>
          <div className="confirmation-email">
            <label className="para-5">
              {t("SHIPPING_INFORMATION.EMAIL_CONF_DESC")}
            </label>
            <div className="text-field-2">
              <label htmlFor="email" className="name">
                {t("SHIPPING_INFORMATION.EMAIL")}
              </label>
              <input
                className={`answer ${errors.email ? "error-red" : ""}`}
                type="text"
                name="email"
                id="email"
                ref={register}
                rules={{ required: true }}
                defaultValue={
                  globalState.shippingInformationData.email
                    ? globalState.shippingInformationData.email
                    : ""
                }
              />
              {errors.email && (
                <p className="error-red-content">{errors.email.message}</p>
              )}
            </div>
            <div className="text-field-2">
              <label htmlFor="confEmail" className="name">
                {t("SHIPPING_INFORMATION.CONF_EMAIL")}
              </label>
              <input
                className={`answer ${errors.confEmail ? "error-red" : ""}`}
                type="text"
                name="confEmail"
                id="confEmail"
                ref={register}
                rules={{ required: true }}
                defaultValue={
                  globalState.shippingInformationData.confEmail
                    ? globalState.shippingInformationData.confEmail
                    : ""
                }
              />
              {errors.confEmail && (
                <p className="error-red-content">{errors.confEmail.message}</p>
              )}
            </div>
          </div>

          <h1 className="texas-driver-license">
            {t("SHIPPING_INFORMATION.ATTESTATION_HEADING")}
          </h1>
          <div className="form-checkbox-default">
            <input
              className={`checkbox ${errors.attestionCheck ? "error-red" : ""}`}
              type="checkbox"
              id="checkbox2"
              name="attestionCheck"
              ref={register}
              rules={{ required: true }}
            />
            <label htmlFor="checkbox2" className="checkbox-attestation">
              {t("SHIPPING_INFORMATION.ATTESTATION_DESCRIPTION")}
            </label>
            {errors.attestionCheck && (
              <p className="error-red-content checkbox-error">
                {errors.attestionCheck.message}
              </p>
            )}
          </div>

          <div className="cc-form">
            {showError && (
              <Row className="shipping-Info-error-message">
                <Col
                  sm={2}
                  xs={2}
                  md={2}
                  lg={2}
                  className="shipping-Info-alert-icon"
                >
                  <img
                    className="shipping-Info-alert"
                    src={alert}
                    alt="alert"
                  />
                </Col>
                <Col
                  className="shipping-Info-error-content"
                  sm={8}
                  xs={8}
                  md={8}
                  lg={8}
                >
                  <p className="shipping-Info-error-text">
                    {t("SHIPPING_INFORMATION_ERROR_MSG.UNABLE_TO_PROCEED")}
                  </p>
                  <p className="shipping-Info-error-desc">
                    {t("SHIPPING_INFORMATION_ERROR_MSG.VERIFY_THE_INFO")}
                  </p>
                </Col>
                <Col
                  className="shipping-Info-close"
                  sm={2}
                  xs={2}
                  md={2}
                  lg={2}
                >
                  <img
                    src={close}
                    alt="close button"
                    onClick={() => setShowError(false)}
                  />
                </Col>
              </Row>
            )}
            <div>
              <Spinner />{" "}
            </div>
            <Row className="btn-group-align" sm={1} xs={1} md={2} lg={2}>
              <Col>
                <Button
                  variant="outline-primary"
                  className="back-button-shipping-Info"
                  onClick={() => props.prevCallback()}
                >
                  {t("COMMONS.BACK")}
                </Button>
              </Col>
              <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
                <Button
                  variant="primary"
                  className="next-button-shipping-Info"
                  disabled={nextButtonDisabled}
                  type="submit"
                >
                  {t("COMMONS.NEXT")}
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </Container>
    </>
  );
}

export default ShippingInfo;

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import SendEmail from "../../../api/SendEmail";
import GetReceipt from "../../../api/GetReceipt";
import useGlobal from "../../../store";
import i18n from "../../../i18n";
import "./OrderReceipt.scss";
import print from "../../../assets/printer.svg";
import history from "./../../common/history";
import { trackPromise } from "react-promise-tracker";
import { Spinner } from "../../common/spinner";

function OrderReceipt(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [receiptDetails, setReceiptDetails] = useState({});
  const [orderDetails, setOrderDetails] = useState([]);

  if (globalState.orderId !== undefined && globalState.orderId !== "") {
    var receiptData = {
      applicationId: "OVRA",
      agencyId: "DSHS",
      orderNumber: globalState.orderId.toString(),
      uniqueIdentifier: globalState.uniqueIdentifier,
    };

    var emailData = {
      applicationId: "OVRA",
      agencyId: "DSHS",
      orderNumber: globalState.orderId.toString(),
      uniqueIdentifier: globalState.uniqueIdentifier,
      language: i18n.language,
    };
  } else {
    history.push(t("URLS.SELECT_VITAL_RECORD"));
  }

  useEffect(() => {
    if (globalState.orderId !== undefined && globalState.orderId !== "") {
      var delayInMilliseconds = 500; //Forced delay of 1 sec to avoid error in order receipt
      setTimeout(function () {
        trackPromise(SendEmail(emailData));
        trackPromise(
          GetReceipt(receiptData).then((response) => {
            {
              globalActions.receiptOrder(response.data);
              setReceiptDetails(response.data);

              if (Array.isArray(response.data.orderDetails) && response.data.orderDetails.length > 0) {
                if (!response.data.orderDetails || !Array.isArray(response.data.orderDetails) || response.data.orderDetails.length === 0) return;

                response.data.orderDetails.map((order) => {
                  if (order.recordType === "Fetal Death Certificate") order.recordType = "Fetal death certificate";
                  if (order.recordType === "StillBirth Certificate") order.recordType = "Certificate of birth resulting in stillbirth"
                })
              };

              setOrderDetails(response.data.orderDetails);
            }
          })
        ).catch(function (error) {
          console.log("ReceiptDetails error", error);
          history.push(t("URLS.ERROR_PAGE"));
        });
      }, delayInMilliseconds);
    } else {
      history.push(t("URLS.SELECT_VITAL_RECORD"));
    }
  }, []);
  return (
    <>
      <Container id="printHTML" className="order-receipt-container">
        <hr />
        <h1 className="order-receipt-heading">{t("ORDER_RECEIPT.HEADING")}</h1>
        <p className="order-receipt-desc">{t("ORDER_RECEIPT.DESCRIPTION")}</p>
        <label className="order-receipt">{t("ORDER_RECEIPT.RECEIPT")}</label>
        <Spinner />
        <form className="order-review-content-section">
          <div className="itemized-total-section">
            <Row className="payment-order-sub-total">
              <Col className="payment-font-style">
                <label>{t("ORDER_RECEIPT.RECORDTOTAL")}</label>
              </Col>
              <Col className="payment-sub-amount">
                <p>
                  $
                  {(
                    parseFloat(receiptDetails.subTotal) +
                    parseFloat(receiptDetails.serviceFee)
                  ).toFixed(2)}
                </p>
              </Col>
            </Row>

            <Row className="payment-order-sub-total-2">
              <Col className="payment-font-style">
                <label>{t("ORDER_RECEIPT.DONATIONS")}</label>
              </Col>
              <Col className="payment-sub-amount">
                {" "}
                <p>${receiptDetails.donationAmount}</p>{" "}
              </Col>
            </Row>

            <Row className="payment-order-sub-total">
              {/* STRY0172062 - added ShippingType localization  */}
              <Col className="payment-font-style">
                {receiptDetails.shippingType ? (
                  <label>
                    {t("ORDER_RECEIPT.SHIPPING_FEE")}{" "}
                    <span>
                      (
                      {t(
                        "COMMONS." +
                          receiptDetails.shippingType
                            .toUpperCase()
                            .replace(" ", "_")
                      )}
                      )
                    </span>
                  </label>
                ) : (
                  ""
                )}
              </Col>
              <Col className="payment-sub-amount">
                {" "}
                <p>${receiptDetails.shippingFee}</p>{" "}
              </Col>
            </Row>

            <Row className="payment-order-sub-total-2">
              <Col>
                <label className="payment-font-style-total">
                  {t("ORDER_RECEIPT.TOTAL")}
                </label>
              </Col>
              <Col className="payment-sub-amount total-color">
                {" "}
                <p>${receiptDetails.total}</p>{" "}
              </Col>
            </Row>
          </div>
          <div>
            {" "}
            <Row
              className="order-information-container"
              sm={1}
              xs={1}
              md={2}
              lg={2}
            >
              <Col>
                <label className="info-titles">
                  {t("ORDER_RECEIPT.ORDER_NUMBER")}
                </label>
                <p className="order-field-detail">
                  {receiptDetails.remittanceNumber}
                </p>

                <label className="info-titles">
                  {t("ORDER_RECEIPT.PAYMENT_METHOD")}
                </label>
                <p className="order-field-detail">
                  {receiptDetails.paymentMethod}
                </p>

                <label className="info-titles">
                  {t("ORDER_RECEIPT.TRANSACTION_NUMBER")}
                </label>
                <p className="order-field-detail">
                  {receiptDetails.transactionNumber}
                </p>
              </Col>
              <Col>
                <label className="info-titles">
                  {t("ORDER_RECEIPT.TRANSACTION_DATE")}
                </label>
                <p className="order-field-detail">
                  {receiptDetails.transactionDate}
                </p>

                <label className="info-titles">
                  {t("ORDER_RECEIPT.PAYMENT_STATUS")}
                </label>
                <p className="order-field-detail-paid">
                  {receiptDetails.paymentStatus}
                </p>
              </Col>
            </Row>
            <div className="record-information">
              <label className="order-receipt">
                {t("ORDER_RECEIPT.RECORD_ORDER_INFORMATION")}
              </label>

              <div className="record-detail-information">
                <div className="order-information-container">
                  {receiptDetails &&
                    orderDetails.map((orderDetailArray, index) => {
                      return (
                        <div>
                          {index !== 0 && <div className="rectangle"></div>}
                          <Row sm={1} xs={1} md={2} lg={2}>
                            <Col>
                              <label className="info-titles">
                                {t("ORDER_RECEIPT.NAME_ON_RECORD")}
                              </label>
                              <p className="order-field-detail">
                                {orderDetailArray.firstName +
                                  " " +
                                  orderDetailArray.middleName +
                                  " " +
                                  orderDetailArray.lastName}
                                <br />
                                {orderDetailArray.spouse2FirstName
                                  ? orderDetailArray.spouse2FirstName
                                  : ""}{" "}
                                {orderDetailArray.spouse2MiddleName
                                  ? orderDetailArray.spouse2MiddleName
                                  : ""}{" "}
                                {orderDetailArray.spouse2LastName
                                  ? orderDetailArray.spouse2LastName
                                  : ""}
                              </p>
                              <label className="info-titles">
                                {t("ORDER_RECEIPT.DATE_RANGE_RECORD")}
                              </label>
                              <p className="order-field-detail">
                                {orderDetailArray.date}
                              </p>
                              <label className="info-titles">
                                {t("ORDER_RECEIPT.AMOUNT")}
                              </label>
                              <p className="order-field-detail">
                                $
                                {parseFloat(orderDetailArray.amount).toFixed(2)}
                              </p>
                            </Col>
                            <Col>
                              <label className="info-titles">
                                {t("ORDER_RECEIPT.RECORD_TYPE")}
                              </label>
                              <p className="order-field-detail">
                                {orderDetailArray.recordType}{" "}
                                {orderDetailArray.recordFormat
                                  ? orderDetailArray.recordFormat
                                  : ""}
                              </p>
                              <label className="info-titles">
                                {t("ORDER_RECEIPT.NUMBER_OF_COPIES")}
                              </label>
                              <p className="order-field-detail">
                                {orderDetailArray.numberOfCopies}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </form>
        <div>
          <Button className="alternate-way-button" onClick={window.print}>
            <img className="printlink" src={print} alt="" />
            <p className="printlink">{t("ORDER_RECEIPT.PRINT")}</p>
          </Button>
        </div>
      </Container>
      <div className="feed-back-bg ">
        <Container className="order-feedback-container">
          <p className="order-feedback-text">
            <span className="feedback-bold">{t("ORDER_RECEIPT.FEEDBACK")}</span>
            {t("ORDER_RECEIPT.FEEDBACK_TEXT")}
          </p>

          <Row className="btn-birth" sm={1} xs={1} md={2} lg={2}>
            <Col>
              <a
                className="feedback-button button-primary"
                href={t("ORDER_RECEIPT.FEEDBACK_LINK")}
                target="blank"
              >
                {t("ORDER_RECEIPT.FEEDBACK_BUTTON")}
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default OrderReceipt;

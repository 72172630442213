import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap";
import Progress from "../../Progress";
import history from "../../history";
import Modal from "react-bootstrap/Modal";

import Select from "react-select";
import add from "../../../../assets/add.svg";
import useGlobal from "../../../../store";
import { NumberField } from "../../SelectField";
import PurposeBirthList from "../../PurposeList";
import { FormInput, FormInputOptional } from "../../FormInput";
import { isMobile } from "react-device-detect";
import MaskedInput from "react-maskedinput";
import GenderList from "../../CommonList";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { ErrorInPage } from "../../index";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import "./FetalDeathInfo.scss";
import { IDGenerator } from "../../CommonList";
import DateFormatter from "../../Date";
import { isMobileOnly } from "react-device-detect";

function FetalDeathInfo(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [showCopiesError, setShowCopiesError] = useState(false);
  const listingOptions = PurposeBirthList();
  const [showParentChecked, setShowParentChecked] = useState(false);
  const [show, setShow] = useState(false);

  const getHelperText = (label) => {
    switch (label) {
      case "Birth Certificate-Full Size":
        return t("BIRTH_CERTITICATE_INFORMATION.LONG_FORM_ACCEPTED");

      case "Birth Certificate-Standard Size":
        return t("BIRTH_CERTITICATE_INFORMATION.SHORT_FORM_ACCEPTED");

      case "Birth Certificate-Heirloom (Flag Image)":
        return t("BIRTH_CERTITICATE_INFORMATION.HEIRLOOM_NOT_ACCEPTED");

      case "Birth Certificate-Heirloom (Bassinet Image)":
        return t("BIRTH_CERTITICATE_INFORMATION.HEIRLOOM_NOT_ACCEPTED");

      default:
        return "";
    }
  };

  const addAdditionalRecord = () => {
    globalActions.addDocumentTypeFlagOne(true);
  };

  const removeAdditionalRecord = () => {
    globalActions.addDocumentTypeFlagOne(false);
  };

  const addSecondAdditionalRecord = () => {
    globalActions.addDocumentTypeFlagTwo(true);
  };

  const removeSecondAdditionalRecord = () => {
    globalActions.addDocumentTypeFlagTwo(false);
  };

  const SignupSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t("BIRTH_CERTITICATE_INFO_ERROR_MSG.FIRST_NAME_MISSING_ERROR"))
      .matches(
        /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
      ),
    middleName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),
    lastName: yup
      .string()
      .required(t("BIRTH_CERTITICATE_INFO_ERROR_MSG.LAST_NAME_MISSING_ERROR"))
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
      ),
    dateOfEvent: yup
      .date()
      .required(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .min(new Date("1780-01-01"), t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .max(new Date(), t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE")),
    selectedGender: yup.object().shape({
      label: yup.string().required(t("COMMON_ERROR_MSG.GENDER_ERROR")),
      value: yup.string().required(t("COMMON_ERROR_MSG.GENDER_ERROR")),
    }),
    cityOfEvent: yup
      .string()
      .required(t("FETALDEATH_INFO.FIELD_VALIDATIONS.CITY_OF_EVENT"))
      .matches(
        /^[a-zA-Z ]*$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.CITY_INVALID_ENTRY")
      ),
    countyOfEvent: yup.object().shape({
      label: yup.string().required(t("FETALDEATH_INFO.FIELD_VALIDATIONS.PLEASE_SELECT_COUNTY_OF_EVENT")),
      value: yup.string().required(t("FETALDEATH_INFO.FIELD_VALIDATIONS.PLEASE_SELECT_COUNTY_OF_EVENT")),
    }),
    firstNameParent1: yup
      .string()
      .required(t("FETALDEATH_INFO.FIELD_VALIDATIONS.PARENT_1_FIRSTNAME"))
      .matches(
        /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("COMMON_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
      ),
    middleNameParent1: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),
    lastNameParent1: yup
      .string()
      .required(t("FETALDEATH_INFO.FIELD_VALIDATIONS.PARENT_1_LASTNAME"))
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("COMMON_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
      ),

    firstNameParent2: yup.string().when("showParentTwo", {
      is: (showParentTwo) => showParentTwo === false,
      then: yup
        .string()
        .required(t("FETALDEATH_INFO.FIELD_VALIDATIONS.PARENT_2_FIRSTNAME"))
        .matches(
          /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
          t("COMMON_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
        ),
    }),

    middleNameParent2: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),

    lastNameParent2: yup.string().when("showParentTwo", {
      is: (showParentTwo) => showParentTwo === false,
      then: yup
        .string()
        .required(t("FETALDEATH_INFO.FIELD_VALIDATIONS.PARENT_2_LASTNAME"))
        .matches(
          /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
          t("COMMON_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
        ),
    }),

    noOfCopies: yup
      .number()
      .typeError(t("COMMON_ERROR_MSG.NO_OF_COPIES_BC"))
      .required(t("COMMON_ERROR_MSG.NO_OF_COPIES_BC"))
      .moreThan(0, t("VALIDATION_COMMON.COPIES_LIMIT_MIN_BC"))
      .lessThan(4, t("VALIDATION_COMMON.COPIES_LIMIT_MIN_BC")),

    noOfCopies2: yup.number().when("inputDocumentTypeFlagOne", {
      is: (inputDocumentTypeFlagOne) => inputDocumentTypeFlagOne === "true",
      then: yup
        .number()
        .typeError(t("COMMON_ERROR_MSG.NO_OF_COPIES_BC"))
        .required(t("COMMON_ERROR_MSG.NO_OF_COPIES_BC"))
        .moreThan(0, t("VALIDATION_COMMON.COPIES_LIMIT_MIN_BC"))
        .lessThan(4, t("VALIDATION_COMMON.COPIES_LIMIT_MIN_BC")),
    }),

    noOfCopies3: yup.number().when("inputDocumentTypeFlagTwo", {
      is: (inputDocumentTypeFlagTwo) => inputDocumentTypeFlagTwo === "true",
      then: yup
        .number()
        .typeError(t("COMMON_ERROR_MSG.NO_OF_COPIES_BC"))
        .required(t("COMMON_ERROR_MSG.NO_OF_COPIES_BC"))
        .moreThan(0, t("VALIDATION_COMMON.COPIES_LIMIT_MIN_BC"))
        .lessThan(4, t("VALIDATION_COMMON.COPIES_LIMIT_MIN_BC")),
    }),
  });

  const { register, handleSubmit, control, errors } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });

  if (props.editIndex !== "") {
    if (
      (globalState.fetalDeathCertificateInfoData.lastName === undefined ||
        globalState.fetalDeathCertificateInfoData.lastName === "") &&
      (globalState.fetalDeathCertificateInfoData.firstName === undefined ||
        globalState.fetalDeathCertificateInfoData.firstName === "")
    ) {
      globalState.fetalDeathCertificateInfoData =
        globalState.totalOrderRecords[props.editIndex];
      setShowParentChecked(globalState.fetalDeathCertificateInfoData.showParentTwo);
    }
  }

  globalState.fetalDeathCertificateInfoData.dateOfEvent = globalState
    .getStartedFetalDeathCertificateData.dateOfEvent
    ? DateFormatter(
      globalState.getStartedFetalDeathCertificateData.dateOfEvent,
      "mm/dd/yyyy"
    )
    : "";

  const BindNoOfCopies = (data) => {
    let orderDetails = [];

    orderDetails.push({
      recordType: data.selectedBirthCertificate,
      numberOfCopies: parseInt(data.noOfCopies),
      purposeList: data.selectedPurposeList,
    });

    if (globalState.documentTypeFlagOne && parseInt(data.noOfCopies2) > 0) {
      orderDetails.push({
        recordType: data.selectedBirthCertificate2,
        numberOfCopies: parseInt(data.noOfCopies2),
        purposeList: data.selectedPurposeList2,
      });
    }
    if (globalState.documentTypeFlagTwo && parseInt(data.noOfCopies3) > 0) {
      orderDetails.push({
        recordType: data.selectedBirthCertificate3,
        numberOfCopies: parseInt(data.noOfCopies3),
        purposeList: data.selectedPurposeList3,
      });
    }
    return orderDetails;
  };

  const onSubmit = (data) => {
    let orderDetails = BindNoOfCopies(data);
    let totalCount = orderDetails
      .map((orderDetail) => orderDetail.numberOfCopies)
      .reduce((totalNumber, noOfCopy) => noOfCopy + totalNumber);

    if (orderDetails.length > 3 || totalCount > 3) {
      setShowCopiesError(true);
    } else {
      data.orderDetails = orderDetails;
      data.totalNoOfCopies = totalCount;
      data.certificateInfoType = "fetalDeath";
      data.selectedState =
        globalState.getStartedFetalDeathCertificateData.selectedState;
      data.selectedRelation =
        globalState.getStartedFetalDeathCertificateData.selectedRelation;
      data.uid = IDGenerator();
      globalActions.addFetalDeathCertificateInfo(data, props.editIndex);
      history.push(t("URLS.ORDER_REVIEW"));
    }
  };

  const countyList = props.countyList;

  const addAnotherRecord = (data) => {
    let orderDetails = BindNoOfCopies(data);
    let totalCount = orderDetails
      .map((orderDetail) => orderDetail.numberOfCopies)
      .reduce((totalNumber, noOfCopy) => noOfCopy + totalNumber);

    if (orderDetails.length > 3 || totalCount > 3) {
      setShowCopiesError(true);
    } else {
      data.orderDetails = orderDetails;
      data.totalNoOfCopies = totalCount;
      data.certificateInfoType = "fetalDeath";
      data.selectedState =
        globalState.getStartedFetalDeathCertificateData.selectedState;
      data.selectedRelation =
        globalState.getStartedFetalDeathCertificateData.selectedRelation;
      data.uid = IDGenerator();
      globalActions.addFetalDeathCertificateInfo(data, props.editIndex);
      history.push(t("URLS.SELECT_VITAL_RECORD"));
    }
  };

  return (
    <div>
      <Progress currentStep={2} totalSteps={4} />
      <h1 className="birth-info-heading">
        {t("FETALDEATH_INFO.TITLE")}
      </h1>
      <form
        className="birth-info-content-section"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="birth-personal-info">
          {t("FETALDEATH_INFO.DESCRIPTION")}
        </h2>

        <FormInput
          id="firstName"
          name="firstName"
          type="text"
          label={t("BIRTH_CERTITICATE_INFORMATION.CHILD_FIRST_NAME")}
          register={register}
          className={`birth-info-input answer ${errors.firstName ? "error-red" : ""
            }`}
          defaultValue={
            globalState.fetalDeathCertificateInfoData.firstName
              ? globalState.fetalDeathCertificateInfoData.firstName
              : ""
          }
          rules={{ required: true }}
          error={errors.firstName}
        />

        <FormInputOptional
          id="middleName"
          name="middleName"
          className="birth-info-input answer"
          type="text"
          label={t("BIRTH_CERTITICATE_INFORMATION.CHILD_MIDDLE_NAME")}
          labelOptional={t("COMMONS.OPTIONAL")}
          register={register}
          defaultValue={
            globalState.fetalDeathCertificateInfoData.middleName
              ? globalState.fetalDeathCertificateInfoData.middleName
              : ""
          }
        />

        <FormInput
          id="lastName"
          name="lastName"
          type="text"
          label={t("BIRTH_CERTITICATE_INFORMATION.CHILD_LAST_NAME")}
          register={register}
          className={`birth-info-input answer ${errors.lastName ? "error-red" : ""
            }`}
          defaultValue={
            globalState.fetalDeathCertificateInfoData.lastName
              ? globalState.fetalDeathCertificateInfoData.lastName
              : ""
          }
          rules={{ required: true }}
          error={errors.lastName}
        />

        <FormInput
          id="dateOfEvent"
          name="dateOfEvent"
          type="text"
          label={t("FETALDEATH_INFO.DATE_OF_EVENT")}
          register={register}
          disabled={true}
          className={`birth-info-input answer ${errors.dateOfEvent ? "error-red" : ""
            }`}
          defaultValue={globalState.fetalDeathCertificateInfoData.dateOfEvent}
        />

        <div className="gender-info">
          <label className="select-input-field-align">
            {t("BIRTH_CERTITICATE_INFORMATION.GENDER")}

            <Controller
              name="selectedGender"
              className={`field-label-padding ${errors.selectedGender ? "error-red" : ""
                }`}
              as={Select}
              options={GenderList()}
              control={control}
              isClearable
              defaultValue={
                globalState.fetalDeathCertificateInfoData.selectedGender.label
                  ? globalState.fetalDeathCertificateInfoData.selectedGender
                  : ""
              }
              rules={{ required: true }}
            />

            {errors.selectedGender && (
              <p className="error-red-content">
                {t("COMMON_ERROR_MSG.GENDER_ERROR")}
              </p>
            )}
          </label>
        </div>
        <FormInput
          id="cityOfEvent"
          name="cityOfEvent"
          type="text"
          label={t("FETALDEATH_INFO.CITY_OF_EVENT")}
          register={register}
          className={`birth-info-input answer ${errors.cityOfEvent ? "error-red" : ""
            }`}
          defaultValue={
            globalState.fetalDeathCertificateInfoData.cityOfEvent
              ? globalState.fetalDeathCertificateInfoData.cityOfEvent
              : ""
          }
          rules={{ required: true }}
          error={errors.cityOfEvent}
        />
        <div className="birth-county-align">
          <label className="select-input-field-align">
            {t("FETALDEATH_INFO.COUNTY_OF_EVENT")}

            <Controller
              name="countyOfEvent"
              className={`field-label-padding ${errors.countyOfEvent ? "error-red" : ""
                }`}
              as={Select}
              options={countyList}
              control={control}
              isClearable
              defaultValue={
                globalState.fetalDeathCertificateInfoData.countyOfEvent.label
                  ? globalState.fetalDeathCertificateInfoData.countyOfEvent
                  : ""
              }
              rules={{ required: true }}
            />

            {errors.countyOfEvent && (
              <p className="error-red-content">
                {t("FETALDEATH_INFO.FIELD_VALIDATIONS.PLEASE_SELECT_COUNTY_OF_EVENT")}
              </p>
            )}
          </label>
        </div>

        <div className="parent-both-info-padding">
          <h2 className="birth-personal-info">
            {t("FETALDEATH_INFO.SUB_HEADING_PARENT")}
          </h2>

          <FormInput
            id="firstNameParent1"
            name="firstNameParent1"
            type="text"
            label={t("FETALDEATH_INFO.LABELS.PARENT_1_FIRSTNAME")}
            register={register}
            className={`birth-info-input answer ${errors.firstNameParent1 ? "error-red" : ""
              }`}
            defaultValue={
              globalState.fetalDeathCertificateInfoData.firstNameParent1
                ? globalState.fetalDeathCertificateInfoData.firstNameParent1
                : ""
            }
            rules={{ required: true }}
            error={errors.firstNameParent1}
          />

          <FormInputOptional
            id="middleNameParent1"
            name="middleNameParent1"
            className="birth-info-input answer"
            type="text"
            labelOptional={t("COMMONS.OPTIONAL")}
            label={t("FETALDEATH_INFO.LABELS.PARENT_1_MIDDLENAME")}
            register={register}
            defaultValue={
              globalState.fetalDeathCertificateInfoData.middleNameParent1
                ? globalState.fetalDeathCertificateInfoData.middleNameParent1
                : ""
            }
          />
          <FormInput
            id="lastNameParent1"
            name="lastNameParent1"
            type="text"
            label={t("FETALDEATH_INFO.LABELS.PARENT_1_LASTNAME")}
            register={register}
            className={`birth-info-input answer ${errors.lastNameParent1 ? "error-red" : ""
              }`}
            defaultValue={
              globalState.fetalDeathCertificateInfoData.lastNameParent1
                ? globalState.fetalDeathCertificateInfoData.lastNameParent1
                : ""
            }
            rules={{ required: true }}
            error={errors.lastNameParent1}
          />
        </div>
        <div className="form-checkbox-default">
          <input
            type="checkbox"
            onChange={() => setShowParentChecked(!showParentChecked)}
            className="checkbox"
            id="parenttwoshow"
            name="showParentTwo"
            ref={register}
            checked={props.editIndex !== "" ? showParentChecked : globalState.fetalDeathCertificateInfoData.showParentTwo}
          />
          <label htmlFor="parenttwoshow" className="checkbox-parent">
            {t("BIRTH_CERTITICATE_INFORMATION.PARENT_CHECKBOX")}
          </label>
        </div>
        {!showParentChecked && (
          <>
            <FormInput
              id="firstNameParent2"
              name="firstNameParent2"
              type="text"
              label={t("FETALDEATH_INFO.LABELS.PARENT_2_FIRSTNAME")}
              register={register}
              className={`birth-info-input answer ${errors.firstNameParent2 ? "error-red" : ""
                }`}
              defaultValue={
                globalState.fetalDeathCertificateInfoData.firstNameParent2
                  ? globalState.fetalDeathCertificateInfoData.firstNameParent2
                  : ""
              }
              rules={{ required: true }}
              error={errors.firstNameParent2}
            />{" "}
            <FormInputOptional
              id="middleNameParent2"
              name="middleNameParent2"
              type="text"
              className="birth-info-input answer"
              labelOptional={t("COMMONS.OPTIONAL")}
              label={t("FETALDEATH_INFO.LABELS.PARENT_2_MIDDLENAME")}
              register={register}
              defaultValue={
                globalState.fetalDeathCertificateInfoData.middleNameParent2
                  ? globalState.fetalDeathCertificateInfoData.middleNameParent2
                  : ""
              }
            />
            <FormInput
              id="lastNameParent2"
              name="lastNameParent2"
              type="text"
              label={t("FETALDEATH_INFO.LABELS.PARENT_2_LASTNAME")}
              register={register}
              className={`birth-info-input answer ${errors.lastNameParent2 ? "error-red" : ""
                }`}
              defaultValue={
                globalState.fetalDeathCertificateInfoData.lastNameParent2
                  ? globalState.fetalDeathCertificateInfoData.lastNameParent2
                  : ""
              }
              rules={{ required: true }}
              error={errors.lastNameParent2}
            />
          </>
        )}
        <div>
          <h2 className="birth-verification-info">
            {t("FETALDEATH_INFO.RECORD_ORDER_AMOUNT")}
          </h2>
          <NumberField
            id="noOfCopies"
            name="noOfCopies"
            type="number"
            className={`birth-info-label ${errors.noOfCopies ? "error-red" : ""}`}
          width="fetal-death"
            register={register}
            label={t("BIRTH_CERTITICATE_INFORMATION.NUMBER_OF_COPIES")}
            defaultValue={
              globalState.fetalDeathCertificateInfoData.noOfCopies
                ? globalState.fetalDeathCertificateInfoData.noOfCopies
                : ""
            }
            error={errors.noOfCopies}
          />
        </div>

        {showCopiesError && (
          <ErrorInPage
            title={t("BIRTH_CERTITICATE_INFORMATION.UNABLE_TO_PROCESS")}
            description={t(
              "BIRTH_CERTITICATE_INFORMATION.UNABLE_TO_PROCESS_DESC"
            )}
            hideCallBack={() => setShowCopiesError(false)}
          />
        )}

        <div>
          <Row className="btn-birth-info" sm={1} xs={1} md={2} lg={2}>
            <Col>
              <Button
                variant="outline-primary"
                className="back-button-birth-info"
                onClick={handleSubmit(addAnotherRecord)}
              >
                {t("COMMONS.ADD_RECORD")}
              </Button>
            </Col>
            <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
              <Button
                variant="primary"
                className="next-button next-button-birth-mobile-align"
                type="submit"
              >
                {t("COMMONS.NEXT")}
              </Button>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
}

export default FetalDeathInfo;
